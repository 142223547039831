import { defineStore } from 'pinia';
import { Ref, ref } from 'vue';

import { FlightViewLegCabinInventoryTactic } from '@/models/FlightModel';
import { CabinCode } from '@/modules/api/application/application-contracts';
import { logger } from '@/modules/monitoring';
import { MessageService } from '@/modules/shared';
import {
  FareGrid,
  SingleInventoryManagement,
  TourOperatorContractDto,
  TourOperatorNameDto,
  singleInventoryManagementService,
} from '@/modules/tour-operator-details/api/single-inventory-management.service';
import { i18n } from '@/plugins/i18n';

export type SingleInventoryManagementFlightKey = `${FlightViewLegCabinInventoryTactic['flightKey']}`;
export type SingleInventoryManagementFlightKeyCabinCode = `${FlightViewLegCabinInventoryTactic['flightKey']}-${CabinCode}`;

const { t, locale } = i18n.global;

export const useSingleInventoryManagementStore = defineStore('singleInventoryManagement', () => {
  const singleInventoryManagementContracts: Ref<Map<SingleInventoryManagementFlightKeyCabinCode, SingleInventoryManagement>> = ref(
    new Map(),
  );
  const singleInventoryManagementFareGrids: Ref<Map<SingleInventoryManagementFlightKey, FareGrid[]>> = ref(new Map());

  const singleInventoryManagementGridDirty: Ref<boolean> = ref(false);
  const selectedLegCabin: Ref<{
    flightKey: FlightViewLegCabinInventoryTactic['flightKey'];
    cabinCode: CabinCode;
    maxAdjustedAllocation: number | undefined;
  } | null> = ref(null);

  const collator = new Intl.Collator(locale.value);
  /**
   * Distinct list of available contract types.
   */
  const tourOperatorContractTypes = ref<TourOperatorContractDto[]>([]);
  /**
   * Distinct list of available tour operator names.
   */
  const tourOperatorNames = ref<TourOperatorNameDto[]>([]);

  function showSingleInventoryManagement(flightKey: string, cabinCode: CabinCode, maxAdjustedAllocation: number | undefined): void {
    selectedLegCabin.value = {
      flightKey,
      cabinCode,
      maxAdjustedAllocation,
    };
  }

  function clearSingleInventoryManagementSelection(): void {
    selectedLegCabin.value = null;
  }

  async function getSingleInventoryManagementContracts(
    flightKey: FlightViewLegCabinInventoryTactic['flightKey'],
    cabinCode: CabinCode,
  ): Promise<SingleInventoryManagement | undefined> {
    let result: SingleInventoryManagement | undefined;
    try {
      if (!singleInventoryManagementContracts.value.has(`${flightKey}-${cabinCode}`)) {
        const singleInventoryManagementResponse: SingleInventoryManagement =
          await singleInventoryManagementService.getTourOperatorContracts(flightKey, cabinCode);
        singleInventoryManagementContracts.value.set(`${flightKey}-${cabinCode}`, singleInventoryManagementResponse);
      }
      result = singleInventoryManagementContracts.value.get(`${flightKey}-${cabinCode}`);
    } catch (e) {
      logger.error(e as Error);
      MessageService.error();
    }
    return result;
  }

  async function saveSingleInventoryManagementContract(
    singleInventoryManagement: SingleInventoryManagement,
    preview: boolean,
  ): Promise<SingleInventoryManagement> {
    let result: SingleInventoryManagement | undefined;
    try {
      const { flightKey, cabinCode } = selectedLegCabin.value ?? {};

      if (!flightKey || !cabinCode) {
        throw new Error('Flight key and cabin code must be selected');
      } else {
        result = await singleInventoryManagementService.saveTourOperatorContract(flightKey, cabinCode, singleInventoryManagement, preview);
        singleInventoryManagementContracts.value.set(`${flightKey}-${cabinCode}`, result);
        if (!preview) {
          singleInventoryManagementGridDirty.value = false;
        }
      }
    } catch (e) {
      logger.error(e as Error);
      MessageService.error();
    }
    if (!result) {
      throw new Error('Failed to save/preview Single Inventory Management Contract');
    }
    return result;
  }

  async function getFareGrids(flightKey: FlightViewLegCabinInventoryTactic['flightKey']): Promise<FareGrid[] | undefined> {
    let result: FareGrid[] | undefined;
    try {
      if (!singleInventoryManagementFareGrids.value.has(`${flightKey}`)) {
        const fareGridsResponse = await singleInventoryManagementService.getFareGrids(flightKey);
        singleInventoryManagementFareGrids.value.set(`${flightKey}`, fareGridsResponse);
      }
      result = singleInventoryManagementFareGrids.value.get(`${flightKey}`);
    } catch (e) {
      logger.error(e as Error);
      MessageService.error();
    }
    return result;
  }

  async function getTourOperatorContractTypes(): Promise<void> {
    try {
      if (!tourOperatorContractTypes.value.length) {
        tourOperatorContractTypes.value = (await singleInventoryManagementService.getTourOperatorContractTypes()).sort((a, b) =>
          collator.compare(a.contractType, b.contractType),
        );
      }
    } catch (e) {
      logger.error(e as Error);
      MessageService.error(t('tour_operator_messages.failed_to_get_contract_types'));
    }
  }

  async function getTourOperatorNames(): Promise<void> {
    try {
      if (!tourOperatorNames.value.length) {
        tourOperatorNames.value = (await singleInventoryManagementService.getTourOperatorNames()).sort((a, b) =>
          collator.compare(a.name, b.name),
        );
      }
    } catch (e) {
      logger.error(e as Error);
      MessageService.error(t('tour_operator_messages.failed_to_get_tour_operator_names'));
    }
  }

  function $reset(): void {
    singleInventoryManagementContracts.value = new Map();
  }

  return {
    selectedLegCabin,
    singleInventoryManagementContracts,
    singleInventoryManagementGridDirty,
    tourOperatorContractTypes,
    tourOperatorNames,
    clearSingleInventoryManagementSelection,
    getFareGrids,
    getSingleInventoryManagementContracts,
    saveSingleInventoryManagementContract,
    showSingleInventoryManagement,
    getTourOperatorContractTypes,
    getTourOperatorNames,
    $reset,
  };
});
