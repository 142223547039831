import { ColDef } from 'ag-grid-enterprise';

import { CabinCode } from '@/modules/api/application/application-contracts';
import { ApiClient } from '@/modules/api/base-client';

type NumberDiff = {
  value: number;
  diff: number;
};

export interface BaseSingleInventoryManagement {
  name: string;
  type: 'cabin' | 'seat_only' | 'pool' | 'tour_operator' | 'pool_tour_operator';
  path?: string[];
  active?: boolean;
  fareGrid?: string;
}

export interface SingleInventoryManagementNonTourOperator extends BaseSingleInventoryManagement {
  type: 'cabin' | 'seat_only' | 'pool';
  fare?: number;
  seats: {
    allocated: number;
    adjustedAllocation: number;
    availability: number;
    sold: NumberDiff;
  };
  calculatedClass?: string;
}

export interface SingleInventoryManagementPool extends Omit<SingleInventoryManagementNonTourOperator, 'type'> {
  type: 'pool';
  tourOperators: SingleInventoryManagementPoolTourOperator[];
}

interface BaseSingleInventoryManagementTourOperator extends BaseSingleInventoryManagement {
  fare: number;
  calculatedClass: string;
  fareGrid: string;
  srcLAF: string;
  offset: number;
}

export interface SingleInventoryManagementTourOperator extends BaseSingleInventoryManagementTourOperator {
  type: 'tour_operator';
  ceiling: string;
  seats: {
    allocated: number;
    adjustedAllocation: number;
    availability: number;
    sold: NumberDiff;
  };
}

export interface SingleInventoryManagementPoolTourOperator extends BaseSingleInventoryManagementTourOperator {
  active: boolean;
  type: 'pool_tour_operator';
  seats: {
    sold: NumberDiff;
  };
}

export type SingleInventoryManagement = {
  cabin: SingleInventoryManagementNonTourOperator;
  seatOnly: SingleInventoryManagementNonTourOperator;
  pool: SingleInventoryManagementPool;
  tourOperators: SingleInventoryManagementTourOperator[];
};

export type SingleInventoryManagementTypes =
  | SingleInventoryManagementNonTourOperator
  | SingleInventoryManagementTourOperator
  | SingleInventoryManagementPool
  | SingleInventoryManagementPoolTourOperator;

export type SingleInventoryManagementColDef = ColDef<SingleInventoryManagementTypes>;

export interface FareGrid {
  fareGridCode: string;
  fares: Fare[];
}

export interface Fare {
  fareClass: string;
  fare: number;
}

export type TourOperatorContractDto = {
  contractType: string;
};

export type TourOperatorNameDto = {
  name: string;
};

class SingleInventoryManagementService {
  private basePath = 'single-inventory-management';

  public async getTourOperatorContracts(flightKey: string, cabinCode: CabinCode): Promise<SingleInventoryManagement> {
    const url = `${this.basePath}/${flightKey}/${cabinCode}`;
    return await ApiClient.get<SingleInventoryManagement>(url);
  }

  public async getFareGrids(flightKey: string): Promise<FareGrid[]> {
    const url = `${this.basePath}/fare-grids/${flightKey}`;
    return await ApiClient.get<FareGrid[]>(url);
  }

  public async saveTourOperatorContract(
    flightKey: string,
    cabinCode: CabinCode,
    singleInventoryManagement: SingleInventoryManagement,
    preview: boolean,
  ): Promise<SingleInventoryManagement> {
    const url = `${this.basePath}/${flightKey}/${cabinCode}?preview=${preview}`;
    return await ApiClient.put<SingleInventoryManagement>(url, singleInventoryManagement);
  }

  public async getTourOperatorContractTypes(): Promise<TourOperatorContractDto[]> {
    const url = `${this.basePath}/tour-operator/contract-types`;
    return await ApiClient.get<TourOperatorContractDto[]>(url);
  }

  public async getTourOperatorNames(): Promise<TourOperatorNameDto[]> {
    const url = `${this.basePath}/tour-operator/names`;
    return await ApiClient.get<TourOperatorNameDto[]>(url);
  }
}

export const singleInventoryManagementService = new SingleInventoryManagementService();
